<template>
    <header class="header">
        <div class="container">
            <nav class="navbar">
                <div class="navbar-right-side">
                    <router-link to="/point/point_home" class="navbar-brand">
                        <img src="images/simpoLogo_black.svg" alt="">
                     </router-link>
                    <div class="navbar-nav" v-if="! is_mobile">
                        <button class="mobile-menu-toggler" @click= "show_menu">
                            <span>ראשי</span>
                            <img src="images/dropdown_arrow.svg" alt="">
                        </button>
                        <ul class="menu-navbar">
                            <li class="nav-item selected">
                                <router-link to="/point/point_home">ראשי</router-link>
                            </li>
                            <li class="nav-item">
                                <router-link to="/point/point_packages">חבילות</router-link>
                            </li>
                            <li class="nav-item">
                                <router-link to="/point/point_customer">לקוחות קצה</router-link>
                            </li>
                        </ul>
                    </div>
                  <div class="navbar-nav" v-if="is_mobile">
                    <button class="mobile-menu-toggler" @click="show_menu">
                      <span>ראשי</span>
                      <img src="images/dropdown_arrow.svg" alt="">
                    </button>
                    <ul class="menu-navbar" style="display: block;" v-if="menu_is_shown">
                      <li class="nav-item selected">
                        <router-link to="/point/point_home">ראשי </router-link>
                      </li>
                      <li class="nav-item">
                        <router-link to="/point/point_packages">חבילות</router-link>
                      </li>
                      <li class="nav-item">
                        <router-link to="/point/point_customer">לקוחות קצה</router-link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="navbar-nav logout-menu">
                    <div class="nav-item">
                        <router-link :to="{path : '/point/point_logout', query : {'id' :id }}">יציאה <img src="images/logout.svg" alt=""></router-link>  
                    </div>
                </div>
            </nav>
        </div>
    </header>
</template>
<script>
export default {
  name: 'PointMenu',
  data() {
    return {
      menu_is_shown: false,
      is_mobile: false
    }
  },
  mounted() {
    if(screen.width > 1000) {
      this.menu_is_shown = true
      this.is_mobile = false
    } else {
      this.is_mobile = true
    }
  },
 methods: {
  show_menu() {
    // this.$router.push({ path: '/point/point_home' })
    this.menu_is_shown = true
  }
 }
}
</script>

<style>
    @import "../../assets/style.css"
</style>