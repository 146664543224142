<template>
  <div v-if="loader || loader_prop">
    Loading...
  </div>
  <div v-if="! (loader || loader_prop)">
    <PointGlobalHeader :global_err="global_err" />
    <div v-if="global_err == ''">
      <PointMenu />
      <div id = "main-cont" >
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import PointMenu from "./PointMenu";
import BackendModel from '../../models/BackendModel'
import PointGlobalHeader from "./PointGlobalHeader"

export default {
  name: 'PointBase',
  props : ['loader_prop'],
  data() {
    return {
      loader : true,
      global_err : '',
    }
  },
  components: {
    PointMenu,
    PointGlobalHeader
  },
  methods: {
    show_mobile_menu() {
      this.mobile_menu_visible = true;
    },
    hide_mobile_menu() {
      this.mobile_menu_visible = false;
    }
  },
  async mounted() {
    const backendModel = new BackendModel()
    this.loader = true
    let backoffice_session_id = backendModel.getBackofficeSessionId()
    let authRes = await backendModel.point_Request('/Api/service/backoffice/distribution_point_auth', {backoffice_session_id})
    if (typeof authRes == 'undefined') {
      this.global_err = 'Backend is down?'
    } else if (authRes.data.res == 'ERROR') {
      this.global_err = authRes.data.e
    } else if (authRes.data.res != 'OK') {
      await this.$router.push({ path: '/' })
    }
    this.loader = false
  }
}
</script>
