<template>
    <PointBase :loader_prop="loader_prop">
        <div class="customer_section">
            <div class="container">
                <div class="customer_header rtl_div">
                    <div class="left_wrap">
                        <h4>לקוחות קצה</h4>
                    </div>
                    <div class="right_wrap">
                      <input type="file" id="excel_input" ref="excel_input" v-on:change="excel_input_upload" style="display:none" />
                      <a v-on:click="upload_excel" href="javascript:void(0);" class="comman_anchor excel_comman_anchor"><img src="images/excel.svg" alt=""> טען אקסל
                            לקוחות קצה</a>

                        <router-link :to="{path : '/point/point_add_customer', query : {id}}" class="comman_anchor">הוספת לקוח קצה </router-link>  
                    </div>
                </div>
                <div class="customer_box_wrap row">
                    <div class="customer_box" v-for="user in view_data" :key="user.id" >
                        <div class="customer_detail">
                            <div class="customer_profile">
                                <div class="customer_image">
                                    <img src="images/user.svg" alt="">
                                </div>
                                <h6>{{user.name}}</h6>
                            </div>
                            <div class="customer_info">
                                <p>{{user.phone_number}}</p>
                                <span>|</span>
                                <p>{{user.email}}</p>
                            </div>
                        </div>
                        <div class="delete_icon">
                            <router-link :to="{path : '/point/point_delete_customr', query : {id: user.id}}"> 
                                <img src="images/delete.svg" alt="">
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </PointBase >
</template>

<script>
    import BackendModel from "../../models/BackendModel";
    import PointBase from "./PointBase";
    import ('./point/css/style.css')

    export default {
      name: 'PointCustomer',
      components: {
        PointBase
      },
      data() {
        return {
            view_data : [],
            id : '',
            loader_prop : true
        }
      },
      async mounted() {
        let backendModel = new BackendModel()
        let view_data =  await backendModel.point_Request("/Api/service/backoffice/point_get_customer",{}) 
        this.view_data = view_data.data.view_data
        let id = view_data.data.id
        this.id = id
        this.loader_prop = false
      },
      methods : {
        upload_excel() {
          console.log("@22")
          this.$refs.excel_input.click()
        },
        async excel_input_upload() {
          this.uploading = true
          this.file_upload_1 = this.$refs.excel_input.files[0]
          let backendModel = new BackendModel()
          let formData = new FormData()
          formData.append('file_upload_1', this.file_upload_1)
          await backendModel.point_Request("/Api/service/backoffice/load_excel", formData)

          backendModel = new BackendModel()
          let view_data =  await backendModel.point_Request("/Api/service/backoffice/point_get_customer",{})
          this.view_data = view_data.data.view_data
          let id = view_data.data.id
          this.id = id
          this.loader_prop = false
        }
      }
    }

</script>

<style>
@import 'point/css/style.css';
</style>
<style>
.rtl_div {
  direction: RTL;
}
</style>
